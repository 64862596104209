body {
    min-height: 100%;
    padding: 0;
    margin: 0;
}

#root > .container {
    /* Add some spacing to the bottom of container to prevent overlap with footer */
    margin-bottom: 5rem;
}

/*
  Using a footer replacement because the footer component covers up content
  when you scroll to the bottom
*/
.footer {
    background-color: var(--bs-primary);
    height: var(--tt-footer-height, 3rem);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.label--bold {
    font-weight: 700;
}

.label--description {
    font-weight: 400;
    color: var(--bs-gray-600);
}

/* For multi-line texts */
.text--multi-line {
    white-space: pre-line;
    line-height: 10px;
}

/* Adding some general spacing to all form elements under the title */
.action-card > *:not(h2):not(:last-child) {
    margin-bottom: 24px;
}

/* Move icon up in certain cases, multi-line texts for example */
.external-link--higher-icon {
    transform: translate(0.125em, -0.6em);
}
